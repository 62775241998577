.clientmain {
    overflow: hidden;
    background: black;
    color: #FF5F40;
    height: auto;
    position: relative;
    h1 {
        font-family: "MurechoBold" !important;
    }
    .header {
        h2 {
            position: relative;
            z-index: 1;
            font-size: 80px;
            font-family: "MurechoBold" !important;
        }
        .background-header {
            position: absolute;
            color: black;
            -webkit-text-stroke: 1px  #ff604051;
            ;
            h1 {
                font-size: 150px;
            }
        }
    }
    .clients-border{
        border:1px solid #FF5F40;
        h3{
            font-family: "Murecho";
            color:white;
        }
    }
    @keyframes bounce {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-50px); }
        100% { transform: translateY(0); }
      }
    .client-section{
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
        transition: background-color 1s ease-in-out 100ms
    }
    .client-section:hover{
        animation-name: bounce;
    animation-timing-function: ease;
    cursor: pointer;
    background-color: #FF5F40;
    }
    .mobile{
        display: none;
    }
    @media only screen and (max-width: 925px) {
        .desktop{
          display: none;
        }
        .mobile{
            display: block;
      }
}
}


