// .portfolioinner{
//     background:url('../../../../assets/portfolioinner.svg');
//     height:120vh;
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-size: cover;
    
   
// }
.banner-height{
  max-height: 800px;
}
.portfolioinner::before{
  content: '';
  position: absolute;
  background: url('../../../../assets/videobg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height:125vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.max-width{
  max-width: 800px !important;
  margin-top:150px;
 }
 .images-section{
  position: relative;
  z-index:1;
 }
 .carousel-image{
  min-height: 150px;
  object-fit: cover;
}
  .portfolioinner-text{
    overflow-x: hidden;
    .portfolio-desc{
      height: 240vh;
    }
    .title{
      background-color: black;
      position: relative;
      z-index:2;
    }
    .footer{
      position: relative;
      z-index: 0;
      h2{
      font-family: "MurechoBold";
      }
      ul{
        li{
          font-family: "Murecho";
        }
      }
    }
    .image{
      position: relative;
      z-index: 1;
    }
    .w-90{
        width:90% !important;
    }
    background-color: black;
    color:white;
    h1 {
        font-family: "MurechoBold" !important;
    text-align: left;

    }
   h4{
        font-family: "Murecho" !important;
    }
    p{
        width:70%;
        font-family: "Murecho" !important;
    }
  }
  @media only screen and (max-width:767px) {
    .portfolioinner-text p{
    width: 100% !important;
  }
  
}
