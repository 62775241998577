.blogsmain {
    overflow: hidden;
    background: black;
    color: white;
    height: auto;
    position: relative;
    h1 {
        font-family: "MurechoBold" !important;
    }
    .header {
        h2 {
            position: relative;
            z-index: 1;
            font-size: 80px;
            font-family: "MurechoBold" !important;
        }
        .background-header {
            position: absolute;
            color: black;
            -webkit-text-stroke: 1px  rgba(255, 255, 255, 0.303);
            ;
            h1 {
                font-size: 150px;
            }
        }
    }
}


