.ourteam {
    .bg-info{
        background-color: #259591 !important;
    }
    overflow-x: hidden;
    background: black;
    color: #259591;
    height: auto;
    position: relative;
    h1 {
        font-family: "MurechoBold" !important;
    }
}

.ourteam-header {
    .background-header {
        position: absolute;
        color: black;
        -webkit-text-stroke: 1px #259591;
        h1 {
            font-size: 150px;
        }
    }
    h2 {
        position: relative;
        z-index: 1;
        font-size: 80px;
        font-family: "MurechoBold" !important;
    }
    @media only screen and (max-width: 925px) {
        h2 {
            font-size: 30px !important;
        }
        h1 {
            font-size: 40px !important;
        }
       
    }
}
.whoweare-text {
    font-family: "Murecho";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    color: white;
}
.arrow{
    width:4% !important;
}
.carousel-container{
        .carousel-slider{
            display: flex;
        }
}
.carousel-row{
    background: conic-gradient(from 165.21deg at 42.21% 100%, rgba(255, 0, 214, 0) 0deg, rgba(30, 192, 173, 0.23) 360deg);
    width:80%;
}