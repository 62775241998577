.contact{
    .mobile{
        display: none;
    }
.contact-right{
    background-color: black;
    p{
        color:white;
        text-align: center;
    }
    .colored-text{
        color:#f99f27;
    }
}
.contact-circle{
    position: absolute;
    top:38%;
    left:38%;
    
}
.form-control{
        background-color: black;
        color:#f99f27;
        border-top:none;
        border-left:none;
        border-right:none;
        border-radius: 0%;
        border-bottom:1px solid #f99f27;
        padding-bottom:2rem;
        padding-left:0 !important;
}
.form-control::placeholder{
    color:#f99f27;
}
.submit-button{
    button{
        background-color: #f99f27;
        border:none;
        color:white;
        font-family: "MurechoBold" !important;
    }
}
.bg-black{
    background-color: black;
}
.lets-talk-img{
    position: absolute;
    z-index:1;
    bottom:0;
    right:0;
}
// @media only screen and (max-width:1320px) {
      
    
//     .desktop{
//      .lets-talk-img{
//         img{
//             width:100%;
//         }
//      }
//      .tower-bg{
//         img{
//           width: 100%;
//         }
//       }
//     }
   
//   }
@media only screen and (max-width: 767px) {
      
    
    .mobile{
      display:initial;
      
      
    }
    .form-mobile{
        position: block;
        z-index:2;
      }
    .tower-bg{
        img{
          width: 100%;
        }
      }
      .contact-circle{
       display: none;
      }
  }
}