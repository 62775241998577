.portfoliomain {
    overflow: hidden;
    background: black;
    color: #EF401F;
    height: auto;
    position: relative;
    h1 {
        font-family: "MurechoBold" !important;
    }
    .carousel-image{
        min-height: 300px;
        object-fit: cover;
    }
    a{
        text-decoration: none;
    }
    .header {
        h2 {
            position: relative;
            z-index: 1;
            font-size: 80px;
            font-family: "MurechoBold" !important;
        }
        .background-header {
            position: absolute;
            color: black;
            -webkit-text-stroke: 1px  #ff60404f;
            ;
            h1 {
                font-size: 150px;
            }
        }
    }
    .clients-border{
        border:1px solid #FF5F40;
    }
}

.portfolio{
    h4{
        color:black;
        font-family: "MurechoBold" !important;
    }
    p{
        color:black;
        font-family: "Murecho" !important;
    }
}
