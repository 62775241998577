.portfolio-main{
    background:url('../../../assets/client-min.png');
    height:120vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .header-text{
      h1{
        color:white;
        font-family: "MurechoBold" !important;
      }
      h6{
        color:white;
        font-family: "Murecho" !important;
      }
      
    }
    .transform-img{
      transform: scaleX(-1);
    }
    .mobile{
      display: none;
    }
   
  }
  .hidden{
    visibility: hidden;
  }
  .position-absolute{
    position: absolute;
    z-index:1
  }
  .position-absolute-icon{
    position: absolute;
    margin:0 auto;
    z-index:99;
    top:10%;
    left:5%
  }
  @media only screen and (max-width: 767px) {
    .desktop{
      display: none;
    }
    .mobile{
      background-color: rgba(0,0,0,0.3);
      display: block;
      .portfolio-main{
      .header-social{
        img{
          width:10% !important;
        }
      }
    }
  }
  }