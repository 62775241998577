.main{
    .bg-black{
      background-color: black;
    }
    background:url('../../../assets/home-min.png');
    height:120vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .video::before{
      content: '';
      position: absolute;
      background: url('../../../assets/videobg.png');
      background-repeat: no-repeat;
      background-size: cover;
      height:125vh;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .header-text{
      h1{
        color:white;
        font-family: "MurechoBold" !important;
      }
      h6{
        color:white;
        font-family: "Murecho" !important;
      }
    }
    .transform-img{
      transform: scaleX(-1);
    }
    .mobile{
      display: none;
    }
   
    @media only screen and (max-width: 767px) {
      
      .desktop{
        display: none;
      }
      .mobile{
      
        background-color: rgba(0,0,0,0.3);
        display: block;
        .header-social{
          img{
            width:10%;
          }
        }
      }
    }
   
  } 
  .mobile-flex{
    display: flex !important;
    justify-content: center !important;
  }
  @media only screen and (max-width: 628px) {
      
    .mobile-flex{
      display: inline-block !important;
    }
  }
  