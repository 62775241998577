.whowemain {
    overflow-y:hidden;
    overflow-x: hidden;
    background: black;
    color: #f99f27;
    height: auto;
    position: relative;
    h1 {
        font-family: "MurechoBold" !important;
    }
}
.background-header {
    position: absolute;
    color: black;
    -webkit-text-stroke: 1px #f99e274c;
    h1 {
        font-size: 150px;
    }
}
.header {
    h2 {
        position: relative;
        z-index: 1;
        font-size: 80px;
        font-family: "MurechoBold" !important;
    }
    @media only screen and (max-width: 925px) {
        .bg-black{background-color: black !important;}
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
        h2 {
            font-size: 30px !important;
        }
        h1 {
            font-size: 40px !important;
        }
       
    }
}
@media only screen and (max-width: 1199px) {
   
    .whoweare-text-container{
        display: flex;
        justify-content: center;
        p{font-size:20px !important;}
    }
}
.whoweare-text {
    font-family: "Murecho";
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    color: white;
}
.seventeen-years{
    font-size:105px;
}
.mobile{
    display: none;
}
@media only screen and (max-width: 925px) {
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
    }
   
}