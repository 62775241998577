.services{
  background:url('../../../assets/services.png');
  height:130vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

 
  .header-text{
    h1{
      color:white;
      font-family: "MurechoBold" !important;
    }
    h6{
      color:white;
      font-family: "Murecho" !important;
    }

  }
  .transform-img{
    transform: scaleX(-1);
  }
  .hidden{
    visibility: hidden;
  }
  .position-absolute{
    position: absolute;
    z-index:1
  }
  .position-absolute-icon{
    position: absolute;
    margin:0 auto;
    z-index:99;
    top:10%;
    left:11%
  }
  .height-image{
    height:auto;
    width:31%;
    
  }
  .header-social{
    position: relative;
  }
  .mobile{
    display: none;
  }
  @media only screen and (max-width: 767px) {
      
      .desktop{
        display: none;
      }
      .mobile{
        background-color: rgba(0,0,0,0.3);
        display: block;
        .header-social{
          img{
            width:10%;
          }
        }
      }
    }
  }
 