.footer-main{
    background-color: white;

    .footer-subscribe{
        color:white;
        background:  #592B86 !important;
    }
    
 .button-subscribe{
    background-color: black;
    height: 50px;
    width:20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
 }
 .input-subscribe{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-left:10px;
    border:none;
    height: 50px;
    width:100%;
 }
 .footer-text{
    font-size:12px;
 }
 ul {
   list-style: none;
   padding: 0;
   display: inline-block;
   position: relative;
   height: 100%; 
   
   li {
     display: block;
     height: calc(100% / 8);
     position: relative;
     
     a {
       display: block;
       position: relative;
       text-decoration: none;
       overflow: hidden;
       font-family: "Murecho";
       -webkit-text-stroke: 1px  #FFA800;
       color:black;
       &:hover:after,
        &:focus:after,
        &:active:after {
          width: 100%;
        }
       &:hover{
         color: #FFA800;
       }
       &:after {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0%;
         width: 0%;
         transform: translateX(-50%);
         height: 3px;
         background: #FFA800;
         transition: .35s;
       }
     }
   }
 }
}
