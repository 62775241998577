.blogs{
    background:url('../../../assets/blogs.svg');
    height:130vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .hidden{
      visibility: hidden;
    }
    .position-absolute{
      position: absolute;
      z-index:1
    }
    .position-absolute-icon{
      position: absolute;
      margin:0 auto;
      z-index:99;
      top:10%;
      left:5%
    }
    .header-text{
      h1{
        color:white;
        font-family: "MurechoBold" !important;
      }
      h6{
        color:white;
        font-family: "Murecho" !important;
      }
    }
    .transform-img{
      transform: scaleX(-1);
    }
    .header-bg{
      background-color: rgba(0,0,0,0.3);
    }
  }
  .blogs-carousel{
    background-color: black;

    .slick-slide  {
      div{
      margin: 0 10px;
    }
    }
    .slick-list {
      margin: 0 -10px;
    }
    .blog-text{
      position:absolute;
      bottom:0;
      background-color: rgba(0, 0, 0, 0.518);
      width:100%;
      margin:0 !important;
      color:white;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-left: 20px;
      height: 30%;
      flex-direction: column;
    }
  
  }
  @media only screen and (max-width: 767px) {
      
    .desktop{
      display: none;
    }
    .mobile{
      background-color: rgba(0,0,0,0.3);
      display: block;
      .blogs{
      .header-social{
        img{
          width:10%;
        }
      }
    }
    }
  }
 