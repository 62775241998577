.premservices {
    background: black;
    color: #9747FF;
    height: auto;
    position: relative;
    h1 {
        font-family: "MurechoBold" !important;
    }
    
    .background-header {
        position: absolute;
        color: black;
        -webkit-text-stroke: 1px  #9747FF80;
        h1 {
            font-size: 150px;
        }
    }
    @media only screen and (max-width: 925px) {
        .background-header{
        
        h1 {
            font-size: 40px !important;
        }
    }
    .header{
        h3 {
            font-size: 30px !important;
            text-align: center;
        }
    }
       
    }
}
.list li{
    color:white !important;
    font-family: "Murecho";
}

.header {
    h3 {
        position: relative;
        z-index: 1;
        font-size: 60px;
        font-family: "MurechoBold" !important;
    }
}
.whoweare-text {
    font-family: "Murecho";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    color: white;
}
.premservices-services{
    background-color: black;
    .branding{
        color: #9747FF;
        font-family: "MurechoBold" !important;
    }
    .digital{
        color:#9747FF;
        font-family: "MurechoBold" !important;
    }
    .pictures{
        color: #9747FF;
        font-family: "MurechoBold" !important;
    }
    p{
        color: #9F9F9F;
        font-family: "Murecho" !important;
        line-height: 30px;
    }
    
}

.services-text{
    border:1px solid #9747FF80;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count:infinite;
    transition: background-color 1s ease-in-out 100ms
}


@keyframes bounce {
    0%   { transform: scale(1); }
    50%  { transform: scale(1.1); }
    100%  { transform: scale(1); }
  }
 
.services-text:hover{
    animation-name: bounce;
animation-timing-function: ease;
background-color: #9747FF80;
}