@import url(https://fonts.googleapis.com/css?family=Varela+Round);

$color-background: #F5F5F5;
$color-main: #1abc9c;
$color-active: #FFF;
$color-link: #FFF;

$button-height: 27px;
$button-width: 35px;
.rayatLogo{
  z-index:99;
  position: absolute;
  top:5%;
}
.localization{
  position: fixed;
  z-index:99;
  right:10%;
  top:5%;
  select{
    background-color: transparent;
    color:white;
    option {
      margin: 40px;
      background: rgb(0, 0, 0);
      color: #fff;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.675);
    }
  }
}
.localization-mobile{
  display: none;
}
@media only screen and (max-width: 767px) {
  .localization-mobile{
    display: block;
    select{
      background-color: transparent;
      color:white;
      option {
        margin: 40px;
        background: rgb(0, 0, 0);
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.675);
      }
    }
  }
  .localization{
   display: none;
  }
  a{font-size:30px !important;}
 
}
.nav-container {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 40%;
  left: 0;
  margin:  0 auto;
  font-family: 'Varela Round', sans-serif;

  p {
    font-size: 20px;
  }
  
  a {
    display: inline-block;
    position: relative;
    text-align: center;
    color: $color-main;
    text-decoration: none;
    font-size: 20px;
    overflow: hidden;
    top: 5px;
    
    &:after {
      content: '';
      position: absolute;
      background: $color-main;
      height: 2px;
      width: 0%;
      transform: translateX(-50%);
      left: 50%;
      bottom: 0;
      transition: .35s ease;
    }
    &:hover:after,
    &:focus:after,
    &:active:after {
      width: 100%;
    }
  }
}

h1 {
  position: relative;
  text-align: center;
  font-family: 'Varela Round', serif;
}

.button_container {
  position: fixed;
  top: 5%;
  right: 2%;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 100;
  transition: opacity .25s ease;
  
  &:hover {
    opacity: .7;
  }
  
  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: white;
    }
    .middle {
      opacity: 0;
      background: white;
    }
    
    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: white;
    }
  }
  
  span {
  background: white;
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition:  all .35s ease;
  cursor: pointer;
    
    &:nth-of-type(2) {
      top: 11px;
    }
    
    &:nth-of-type(3) {
      top: 22px;
    }
  }
}

.overlay {
  position: fixed;
  background: url('../../../assets/menubackground.svg');
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 1;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;
  
  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index:99;
    
    li {
      animation: fadeInRight .5s ease forwards;
      animation-delay: .35s;
      
      &:nth-of-type(2) {
        animation-delay: .4s;
      }
      &:nth-of-type(3) {
        animation-delay: .45s;
      }
      &:nth-of-type(4) {
        animation-delay: .50s;
      }
    }
  }
  nav {
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
    font-family: 'Varela Round', serif;
    font-weight: 400;
    text-align: center;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%; 
    
    li {
      display: block;
      height: 25%;
      height: calc(100% / 5);
      min-height: 50px;
      position: relative;
      opacity: 0;
      
      a {
        display: block;
        position: relative;
        text-decoration: none;
        overflow: hidden;
        font-family: "Murecho";
        -webkit-text-stroke: 1px  #FFA800;
        color:black;
        &:hover:after,
         &:focus:after,
         &:active:after {
           width: 100%;
         }
        &:hover{
          color: #FFA800;
        }
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 3px;
          background: #FFA800;
          transition: .35s;
        }
      }
    }
  }
}


.mobile-social{
  display: none !important;
}

@media only screen and (max-width: 767px) {
      .mobile-social{
        display: flex !important;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        position: relative;
        z-index: 100;
      }
  ul {
   
    
    li {
    
      height: calc(100% / 8) !important;
    }
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}