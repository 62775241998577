@font-face {
  font-family: "MurechoBold";
  src: local("MurechoBold"),
   url("./fonts/Murecho/AlilatoExtraBold.ttf") format("truetype");
  font-weight: bold;
 }
 @font-face {
  font-family: "Murecho";
  src: local("Murecho"),
   url("./fonts/Murecho/AlilatoRegular.ttf") format("truetype");
  font-weight: bold;
 }
body {
  margin: 0;
  font-family: 'MurechoBold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import '~bootstrap/scss/bootstrap.scss';